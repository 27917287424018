import * as React from 'react'

import { Info } from '@mui/icons-material'
import { Container, Typography, darken, lighten, useTheme } from '@mui/material'

import { IconCard, LandingCtaButton, Subtitle, Title } from 'shared/components'
import { isLoggedIn } from 'shared/services'

const HeroSection = () => {
  const theme = useTheme()

  return (
    <Container
      maxWidth='lg'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'min(85vh, 950px)',
        gap: 4,
        px: 1,
        py: 3,
      }}
    >
      <Title
        mainTitle
        colorful
        fontWeight={800}
        textAlign='center'
      >
        Compra y vende dólares digitales a los mejores precios del mercado
      </Title>
      <Subtitle
        mainTitle
        fontWeight={300}
        textAlign='center'
      >
        Plataforma digital para comprar y vender dólares 24/7.
        <br style={{ display: 'block', content: '""', height: '0.5em' }} />
        Habla directamente con una persona, controla todo desde tu cuenta.
      </Subtitle>
      <LandingCtaButton
        id='hero-cta'
        size='large'
        sx={{ px: 4 }}
        isLoggedIn={isLoggedIn}
      />
      <IconCard
        elevation={0}
        icon={<Info />}
        color={darken(theme.palette.info.light, 0.6)}
        bgcolor={lighten(theme.palette.info.light, 0.8)}
        sx={{ border: 1, borderColor: 'info.dark' }}
      >
        <Typography
          fontWeight='bold'
          component='span'
          display='block'
        >
          Entidad Reportante
        </Typography>
        <Typography
          component='span'
          display='block'
        >
          Reportamos activamente a la Unidad de Análisis Financiero (UAF) para
          prevenir el lavado de activos y financiamiento del terrorismo
        </Typography>
      </IconCard>
    </Container>
  )
}

export default HeroSection
