import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection, SeoHeaders } from 'shared/components'

import HeroSection from '../components/landing/hero_section'
import ScalingWrapper from '../components/scaling_wrapper'
import Layout from '../layouts/landing'

const IndexPage = () => {
  return (
    <Layout>
      <SeoHeaders description='Compra y vende dólares digitales a los mejores precios del mercado' />
      <HeroSection />
      <BaseSeoSection
        maxWidth='lg'
        seoTitle='Excelencia en cada transacción con tasas líderes y servicio de calidad superior.'
        seoSubtitle={
          'Navega el mercado con las tasas más competitivas en dólares digitales,'
          + ' y experimenta un servicio excepcional que transformará tu manera de comprar y vender.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/wave.png'
              alt='Fugiro wave'
              loading='eager'
              placeholder='none'
              width={900}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        reversed
        maxWidth='lg'
        seoTitle='Automatización que inspira confianza.'
        seoSubtitle={
          'Vive la tranquilidad de operar con una plataforma que garantiza seguridad y eficiencia'
          + ' en cada transacción, asegurando tu paz mental y la protección de tus inversiones.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/psychedelic.png'
              alt='Fugiro psychedelic'
              loading='eager'
              placeholder='none'
              width={900}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        maxWidth='lg'
        seoTitle='Escalabilidad sin límites para tus transacciones más ambiciosas.'
        seoSubtitle={
          'Descubre el poder de una plataforma preparada para soportar las mayores demandas del '
          + 'mercado, permitiéndote operar sin límites y con la certeza de que cada transacción,'
          + ' grande o pequeña, se maneja con la máxima eficiencia.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/dashboard.png'
              alt='Fugiro dashboard'
              loading='eager'
              placeholder='none'
              width={900}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
      <BaseSeoSection
        reversed
        maxWidth='lg'
        seoTitle='Una velocidad inigualable en la compra-venta de dólares digitales.'
        seoSubtitle={
          'Explora cómo las transacciones ultrarrápidas en Fugiro elevan el estándar de compra'
          + ' y venta de dólares digitales, con un equilibrio perfecto entre velocidad, seguridad'
          + ' y facilidad de uso.'
        }
        imageComponent={(
          <ScalingWrapper>
            <StaticImage
              src='../images/landing/crane.png'
              alt='Fugiro shades'
              loading='eager'
              placeholder='none'
              width={900}
              quality={100}
            />
          </ScalingWrapper>
        )}
      />
    </Layout>
  )
}

export default IndexPage
